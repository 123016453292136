import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import styles from './Navbar.module.scss';
import TaliIcon from '../../public/images/downloads/tali-logo.svg';
import DropDown from '../../public/images/drop-down.svg';
import Up from '../../public/images/up.svg';
import LogoutIcon from '../../public/images/logout-icon.svg';
import Avatar from '../../public/images/avatar.svg';
import Separator from '../../public/images/downloads/separator-icon.svg';
import SmallTaliIcon from '../../public/images/downloads/small-tali-logo.svg';
import Close from '../../public/images/downloads/close-icon.svg';
import MenuBar from '../../public/images/menu-bar.svg';
import { useOutSideAerter } from '../useoutSideClickHandle';
import Text from '../display/Text';
import { addEvent } from '../../services/gtag';
import { AuthContext } from '~/contexts/authentication';
import CanUpgradeButton from '../buttons/CanUpgrade';


const mobileBreakpoint = '(max-width: 1000px)';

enum PageURL {
  features = '/features',
  pricing = '/pricing',
  resources = '/resources',
  faq='/faq',
  install = '/install',
  profile = '/user-profile',
}

export default function Navbar() {
  const router = useRouter();
  const { user, isLoading, isAuthenticated} = useContext(AuthContext);
  const [onScroll, setOnscroll] = useState<boolean>(false);
  const [menuBarClicked, setMenuBarClicked] = useState<boolean>(false);
  const [name, setName] = useState<string>('');



  useEffect(() => {
    if (isLoading) {
      const profileName = localStorage.getItem('profileName') || '';
      setName(profileName);
      return;
    }
    if (isAuthenticated) {
      setName(user?.firstName || '');
    }
  }, [isLoading,isAuthenticated, user]);

  const handleClick = () => {
    setDropDown(!dropDown);
  };
  const { dropDown, setDropDown, ref } = useOutSideAerter(false);

  const defaultMenu = (
    <div className={styles.menu}>
      <Link href={PageURL.features} passHref>
        <div className={styles.linkWrapper} key='1'>
          <a>Product</a>
          <div
            className={clsx(
              router.pathname === PageURL.features
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.pricing} passHref>
        <div className={styles.linkWrapper} key='2'>
          <a>Pricing</a>
          <div
            className={clsx(
              router.pathname === PageURL.pricing
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.faq} passHref>
        <div className={styles.linkWrapper} key='3'>
          <a>FAQ</a>
          <div
            className={clsx(
              router.pathname === PageURL.faq
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.install} passHref>
        <div className={styles.linkWrapper} key='4'>
          <a>Install</a>
          <div
            className={clsx(
              router.pathname === PageURL.install
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.resources} passHref>
        <div className={styles.linkWrapper} key='5'>
          <a>Resources</a>
          <div
            className={clsx(
              router.pathname === PageURL.resources
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      {isLoading ? (
        <div className={styles.authPlaceHolder} />
      ) : !isAuthenticated ? (
        <>
          <Link href='/api/auth/login' passHref>
            <div
              className={styles.linkWrapper}
              key='5'
              onClick={() => addEvent('login')}
            >
              <a>Sign In</a>
            </div>
          </Link>

          <div key='6'>
            <Image src={Separator} alt='Separator' key={'Separator'} />
          </div>
          <Link href='/api/auth/login' passHref>
            <div
              key='6'
              className={styles.lastItem}
              onClick={() => addEvent('navRegisterButton')}
            >
              <a>Get Started</a>
            </div>
          </Link>
        </>
      ) : (
        <>
          <CanUpgradeButton className={styles.lastItemOnScroll} />
          <div key='6'>
            <Image src={Separator} alt='Separator' key={'Separator'} />
          </div>
          <div className={styles.flexD} key='5'>
            <div
              className={clsx(styles.flexD, styles.pointer)}
              onClick={handleClick}
              ref={ref}
              role='presentation'
            >
              <div className={styles.avatarWrapper}>
                <Image src={Avatar} alt='profile' />
              </div>
              <div className={styles.margin}>{name}</div>
              {!dropDown ? (
                <Image src={DropDown} alt='click' />
              ) : (
                <Image src={Up} alt='click' />
              )}
            </div>
            {dropDown && (
              <div className={styles.anchorMenu}>
                <Link href={PageURL.profile} passHref>
                  <a className={styles.menuItem}>Profile</a>
                </Link>
                <Link href='/api/auth/logout' passHref>
                  <a>
                    <div
                      className={`${styles.menuItem} ${styles.logoutWrapper}`}
                      role='presentation'
                    >
                      <div className={styles.marginR}>
                        <Image src={LogoutIcon} alt='logout' />
                      </div>
                      <span>Logout</span>
                    </div>
                  </a>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );

  // Sticky Scroll Menu
  const scrollingMenu = (
    <div className={styles.menu}>
      <Link href={PageURL.features} passHref>
        <div className={styles.linkWrapper} key='1'>
          <a>Product</a>
          <div
            className={clsx(
              router.pathname === PageURL.features
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.pricing} passHref>
        <div className={styles.linkWrapper} key='1'>
          <a>Pricing</a>
          <div
            className={clsx(
              router.pathname === PageURL.pricing
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.faq} passHref>
        <div className={styles.linkWrapper} key='2'>
          <a>FAQ</a>
          <div
            className={clsx(
              router.pathname === PageURL.faq
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.install} passHref>
        <div className={styles.linkWrapper} key='3'>
          <a>Install</a>
          <div
            className={clsx(
              router.pathname === PageURL.install
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      <Link href={PageURL.resources} passHref>
        <div className={styles.linkWrapper} key='2'>
          <a>Resources</a>
          <div
            className={clsx(
              router.pathname === PageURL.resources
                ? styles.activeLinkIndicator
                : styles.hide
            )}
          />
        </div>
      </Link>
      {isLoading ? (
        <div className={styles.authPlaceHolder} />
      ) : !isAuthenticated ? (
        <>
          <Link href='/api/auth/login' passHref>
            <div
              className={styles.linkWrapper}
              key='5'
              onClick={() => addEvent('login')}
            >
              <a>Sign In</a>
            </div>
          </Link>

          <div key='6'>
            <Image src={Separator} alt='Separator' key={'Separator'} />
          </div>
          <Link href='/api/auth/login' passHref>
            <div
              key='3'
              className={styles.lastItemOnScroll}
              onClick={() => addEvent('navRegisterButton')}
            >
              <a>Get Started</a>
            </div>
          </Link>
        </>
      ) : (
        <>
          <CanUpgradeButton className={styles.lastItemOnScroll} />
          <div key='6'>
            <Image src={Separator} alt='Separator' key={'Separator'} />
          </div>
          <div className={styles.flexD} key='5'>
            <div
              className={clsx(styles.flexD, styles.pointer)}
              onClick={handleClick}
              ref={ref}
              role='presentation'
            >
              <div className={styles.avatarWrapper}>
                <Image src={Avatar} alt='profile' />
              </div>
              <div className={styles.margin}>{name}</div>
              {!dropDown ? (
                <Image src={DropDown} alt='click' />
              ) : (
                <Image src={Up} alt='click' />
              )}
            </div>
            {dropDown && (
              <div className={styles.anchorMenu}>
                <Link href={PageURL.profile} passHref>
                  <a className={styles.menuItem}>Profile</a>
                </Link>
                <Link href='/api/auth/logout' passHref>
                  <a>
                    <div
                      className={`${styles.menuItem} ${styles.logoutWrapper}`}
                      role='presentation'
                    >
                      <div className={styles.marginR}>
                        <Image src={LogoutIcon} alt='logout' />
                      </div>
                      <span>Logout</span>
                    </div>
                  </a>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );

  const mobileMenu = (
    <div className={styles.openMenuBar}>
      {isAuthenticated ? (
        <>
          <CanUpgradeButton className={styles.lastItemOnScroll} />
          <Link href={PageURL.profile} passHref>
            <div className={styles.mobileLinkWrapper} key='1'>
              {router.pathname === PageURL.profile && (
                <div className={styles.mobileActiveLinkIndicator} />
              )}
              <a>Profile</a>
              {router.pathname === PageURL.profile && (
                <div className={styles.mobileActiveLinkIndicator} />
              )}
            </div>
          </Link>
          <Link href='/api/auth/logout' passHref>
            <div className={styles.mobileLinkWrapper} key='1'>
              <a>Logout</a>
            </div>
          </Link>
        </>
      ) : (
        <Link href='/api/auth/login' passHref>
          <div
            className={styles.mobileLinkWrapper}
            key='1'
            onClick={() => addEvent('login')}
          >
            <a>Sign In</a>
          </div>
        </Link>
      )}
      <div className={styles.mobileDivader} />
      <Link href={PageURL.features} passHref>
        <div className={styles.mobileLinkWrapper} key='1'>
          {router.pathname === PageURL.features && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
          <a>Product</a>
          {router.pathname === PageURL.features && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
        </div>
      </Link>
      <Link href={PageURL.pricing} passHref>
        <div className={styles.mobileLinkWrapper} key='1'>
          {router.pathname === PageURL.pricing && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
          <a>Pricing</a>
          {router.pathname === PageURL.pricing && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
        </div>
      </Link>
      <Link href={PageURL.faq} passHref>
        <div className={styles.mobileLinkWrapper} key='2'>
          {router.pathname === PageURL.faq && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
          <a>FAQ</a>
          {router.pathname === PageURL.faq && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
        </div>
      </Link>
      <Link href={PageURL.install} passHref>
        <div className={styles.mobileLinkWrapper} key='1'>
          {router.pathname === PageURL.install && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
          <a>Install</a>
          {router.pathname === PageURL.install && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
        </div>
      </Link>
      <Link href={PageURL.resources} passHref>
        <div className={styles.mobileLinkWrapper} key='2'>
          {router.pathname === PageURL.resources && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
          <a>Resources</a>
          {router.pathname === PageURL.resources && (
            <div className={styles.mobileActiveLinkIndicator} />
          )}
        </div>
      </Link>
    </div>
  );

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 72) {
      setOnscroll(true);
    } else {
      setOnscroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Update state if we move to/from mobile view
      const isMobileView = window.matchMedia(mobileBreakpoint).matches;
      if (!isMobileView) {
        setMenuBarClicked(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Mobile Menu
  if (menuBarClicked) {
    return (
      <div className={styles.mobileMenu}>
        <div
          className={clsx(
            onScroll ? styles.miniHeader : styles.mobileNotScrolled
          )}
        >
          <div className={styles.headerContainer}>
            <div className={styles.taliLogo}>
              <Link href='/' passHref>
                <div>
                  <Image
                    src={onScroll ? SmallTaliIcon : TaliIcon}
                    alt='Tali Logo'
                    className={styles.clickable}
                  />
                </div>
              </Link>
            </div>
            <div className={styles.flexDMobile}>
              {!isAuthenticated && !isLoading ? (
                <Link href='/api/auth/login' passHref>
                  <div
                    key='6'
                    className={clsx(styles.lastItemOnScroll)}
                    onClick={() => addEvent('navRegisterButton')}
                  >
                    <a>
                      <Text size='semiBoldParagraph2' title='Get Started' />
                    </a>
                  </div>
                </Link>
              ) : (
                <Link href={PageURL.profile} passHref>
                  <a className={styles.avatarWrapper}>
                    <Image src={Avatar} alt='profile' />
                  </a>
                </Link>
              )}
              <div className={styles.closeIconWrapper}>
                <button
                  onClick={() => {
                    setMenuBarClicked(!menuBarClicked);
                  }}
                  className={styles.closeIcon}
                >
                  <Image src={Close} alt='Close Menu' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.menuContainer}>{mobileMenu}</div>
      </div>
    );
  }

  return (
    <div>
      <div className={clsx(onScroll ? styles.miniHeader : styles.header)}>
        <div className={styles.headerContainer}>
          <div className={styles.taliLogo}>
            <Link href='/' passHref>
              <div>
                <Image
                  src={onScroll ? SmallTaliIcon : TaliIcon}
                  alt='Tali logo'
                  className={styles.clickable}
                />
              </div>
            </Link>
          </div>
          {onScroll ? scrollingMenu : defaultMenu}
          <div className={styles.flexDMobile}>
            {!isAuthenticated && !isLoading ? (
              <Link href='/api/auth/login' passHref>
                <div
                  key='6'
                  className={clsx(
                    onScroll ? styles.lastItemOnScroll : styles.lastItem
                  )}
                  onClick={() => addEvent('navRegisterButton')}
                >
                  <a>
                    <Text size='semiBoldParagraph2' title='Get Started' />
                  </a>
                </div>
              </Link>
            ) : (
              <Link href={PageURL.profile} passHref>
                <a className={styles.avatarWrapper}>
                  <Image src={Avatar} alt='profile' />
                </a>
              </Link>
            )}
            <button
              onClick={() => {
                setMenuBarClicked(!menuBarClicked);
              }}
              className={styles.menuBar}
            >
              <div>
                <Image src={MenuBar} alt='Mobile Menu' />
              </div>
            </button>
          </div>
        </div>
      </div>
      {onScroll && <div className={styles.placeHolder}></div>}
    </div>
  );
}
